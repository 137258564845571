// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arbeitskreis-index-js": () => import("/opt/build/repo/src/pages/arbeitskreis/index.js" /* webpackChunkName: "component---src-pages-arbeitskreis-index-js" */),
  "component---src-pages-beratung-angehoerige-js": () => import("/opt/build/repo/src/pages/beratung/angehoerige.js" /* webpackChunkName: "component---src-pages-beratung-angehoerige-js" */),
  "component---src-pages-beratung-index-js": () => import("/opt/build/repo/src/pages/beratung/index.js" /* webpackChunkName: "component---src-pages-beratung-index-js" */),
  "component---src-pages-beratung-medienabhaengigkeit-js": () => import("/opt/build/repo/src/pages/beratung/medienabhaengigkeit.js" /* webpackChunkName: "component---src-pages-beratung-medienabhaengigkeit-js" */),
  "component---src-pages-beratung-motivationsgruppe-js": () => import("/opt/build/repo/src/pages/beratung/motivationsgruppe.js" /* webpackChunkName: "component---src-pages-beratung-motivationsgruppe-js" */),
  "component---src-pages-beratung-pathologisches-gluecksspiel-js": () => import("/opt/build/repo/src/pages/beratung/pathologisches-gluecksspiel.js" /* webpackChunkName: "component---src-pages-beratung-pathologisches-gluecksspiel-js" */),
  "component---src-pages-beratung-rehabilitation-und-nachsorge-js": () => import("/opt/build/repo/src/pages/beratung/rehabilitation-und-nachsorge.js" /* webpackChunkName: "component---src-pages-beratung-rehabilitation-und-nachsorge-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

